import styled from 'styled-components'
import { Flex } from 'rebass'
import { connect } from 'react-redux'
import { getQuickQuoteInsuredInformation, totalPremiumPlusLumpSumTopUp } from 'e-submission/apps/selectors'
import { BaseDetailBox, RiderTags, NameBox } from './index'
import { contentMaxWidth } from 'e-submission/components/mixins'
import NumberInput from 'react-number-format'
import CONSTANTS from 'core/data-model/constants/product'
import MESSAGES from 'core/data-model/constants/messages'
import { Modal } from 'common-components/index'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from 'e-submission/components/Button'
import { changeCurrentApp, reGenBI, reValidateAge } from 'e-submission/apps/actions'
import { compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import React from 'react'

const ProductDetail = styled(Flex)`
  padding-top: 10px;
`

export const firstPeriod = (productName) => {
  let title = MESSAGES.FIRST_PAYMENT_TITLE

  if (productName === CONSTANTS.IINVEST_PRODUCT_NAME) {
    title = MESSAGES.ONE_TIME_PAYMENT_TITLE
  }
  return title + ': '
}

const _PremiumCalStatusInvalidDialog = ({ isOpen, onConfirm, dispatch }) => {
  return (
    <Modal
      isOpen={isOpen}
      backdrop={true}
      size="md"
      modalClassName="default-bootstrap login-session-limit-selling-modal"
    >
      <ModalHeader>ใบคำขอนี้ไม่สามารถใช้งานได้</ModalHeader>
      <ModalBody>
        <p>เนื่องจากอายุรับประกันไม่เข้าเงื่อนไข ส่งผลให้ใบคำขอนี้ไม่สามารถใช้งานได้</p>
        <p>กรุณากด ยืนยัน เพื่อเปลี่ยนแบบประกัน</p>
      </ModalBody>
      <ModalFooter>
        <div className="bottom">
          <Button
            color="primary"
            onClick={() => {
              dispatch(changeCurrentApp(''))
              onConfirm()
            }}
          >
            ยืนยัน
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export const PremiumCalStatusInvalidDialog = compose(
  withRouter,
  withHandlers({
    onConfirm: ({ history }) => {
      history.push('/')
    },
  }),
  connect((state, dispatch) => ({
    isOpen:
      _.get(state, ['apps', 'byId', `${_.get(state, ['apps', 'currentAppId'])}`, 'checkedPremiumCalStatus'], 'Y') ===
      'N',
    state,
    dispatch,
  }))
)(_PremiumCalStatusInvalidDialog)

const PremiumChangeModal = styled(Dialog)`
  .modal-header {
    padding: 20px;
  }
  .modal-title {
    display: flex;
    flex: 1 0 auto;
    font-weight: bold;
    margin-left: 30px;
    justify-content: space-between;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .btn-right {
    color: #555555;
  }
  .modal-body {
    padding: 50px 20px;
    align-items: center;
    background: white;
    display: flex;
    flex-direction: column;
    button {
      margin: 10px;
      width: 70%;
      @media (min-width: 768px) {
        width: 50%;
      }
    }
  }
`

const _PremiumChangeDialog = ({ isOpen, isLoading, onExit, dispatch, newTotalPremium, appId }) => {
  let bodyContent = (
    <>
      <p>เนื่องจากวันเดือนปีเกิดลูกค้าที่ระบุไม่ตรงกับบัตรประชาชน</p>
      <p>ทำให้อายุผู้เอาประกันเปลี่ยนส่งผลต่อเบี้ยประกัน</p>
      <p>
        เบี้ยประกันใหม่{' '}
        <i style={{ color: '#2425aa', fontWeight: 'bold', fontStyle: 'normal' }}>{newTotalPremium.toLocaleString()}</i>{' '}
        บาท
      </p>
      <p>กรุณากด ยืนยัน เพื่อดำเนินการตามขั้นตอนถัดไป</p>
    </>
  )
  let footerContent = (
    <div className="bottom">
      <Button
        color="primary"
        onClick={() => {
          dispatch(reGenBI(appId, 'CPE'))
        }}
      >
        ยืนยัน
      </Button>
    </div>
  )

  if (isLoading) {
    bodyContent = (
      <div id="loader">
        <div id="spinner" />
      </div>
    )
    footerContent = null
  }
  return (
    <PremiumChangeModal isOpen={isOpen} backdrop={true} size="md" modalClassName="default-bootstrap">
      <ModalHeader tag="div">
        <div />
        <div>แจ้งเปลี่ยนแปลงเบี้ยประกัน</div>
        <div
          className="btn-right"
          onClick={() => {
            dispatch(changeCurrentApp(''))
            onExit()
          }}
        >
          <Icon.close className="icon" />
        </div>
      </ModalHeader>
      <ModalBody>{bodyContent}</ModalBody>
      <ModalFooter>{footerContent}</ModalFooter>
    </PremiumChangeModal>
  )
}

export const PremiumChangeDialog = compose(
  withRouter,
  withHandlers({
    onExit: ({ history }) => {
      history.push('/')
    },
  }),
  connect((state, dispatch) => ({
    isOpen:
      _.get(state, ['apps', 'byId', `${_.get(state, ['apps', 'currentAppId'])}`, 'checkedPremiumCalStatus'], 'N') ===
      'Y',
    newTotalPremium: _.get(
      state,
      ['apps', 'byId', `${_.get(state, ['apps', 'currentAppId'])}`, 'newPremiumCalData', 'totalPremium'],
      'NaN'
    ),
    appId: `${_.get(state, ['apps', 'currentAppId'])}`,
    state,
    dispatch,
    isLoading: _.get(state, ['ui', 'isModalLoading'], false),
  }))
)(_PremiumChangeDialog)

const _PremiumCalErrorDialog = ({ isOpen, isLoading, currentAppId, onExit, dispatch }) => {
  let bodyContent = <p>กรุณากดปุ่ม ยืนยัน เพื่อลองอีกครั้ง หรือ กดปุ่ม X เพื่อกลับสู่เมนูหลัก</p>
  let footerContent = (
    <div className="bottom">
      <Button
        color="primary"
        onClick={() => {
          dispatch(reValidateAge(currentAppId))
        }}
      >
        ยืนยัน
      </Button>
    </div>
  )

  if (isLoading) {
    bodyContent = (
      <div id="loader">
        <div id="spinner" />
      </div>
    )
    footerContent = null
  }
  return (
    <PremiumChangeModal isOpen={isOpen} backdrop={true} size="md" modalClassName="default-bootstrap">
      <ModalHeader tag="div">
        <div />
        <div>ขณะนี้ระบบไม่สามารถใช้งานได้</div>
        <div
          className="btn-right"
          onClick={() => {
            dispatch(changeCurrentApp(''))
            onExit()
          }}
        >
          <Icon.close className="icon" />
        </div>
      </ModalHeader>
      <ModalBody>{bodyContent}</ModalBody>
      <ModalFooter>{footerContent}</ModalFooter>
    </PremiumChangeModal>
  )
}

export const PremiumCalErrorDialog = compose(
  withRouter,
  withHandlers({
    onExit: ({ history }) => {
      history.push('/')
    },
  }),
  connect((state, dispatch) => ({
    isOpen:
      _.get(state, ['apps', 'byId', `${_.get(state, ['apps', 'currentAppId'])}`, 'checkedPremiumCalStatus'], 'Y') ===
      'API Error',
    state,
    dispatch,
    currentAppId: `${_.get(state, ['apps', 'currentAppId'])}`,
    isLoading: _.get(state, ['ui', 'isModalLoading'], false),
  }))
)(_PremiumCalErrorDialog)

const InsuredInformationCard = styled(({ className, name, productName, riders, totalPremium, lumpSumTopUp }) => (
  <div className={className}>
    <PremiumCalStatusInvalidDialog />
    <PremiumChangeDialog />
    <PremiumCalErrorDialog />
    <Flex justify="space-between" wrap>
      <NameBox flex={['1 0 100%', null, null, '0 1 auto']}>{name || '-'}</NameBox>
      <ProductDetail wrap flex={['1 0 100%', null, null, '0 1 auto']}>
        <BaseDetailBox>
          <strong className="productName">{productName || '-'}</strong>
        </BaseDetailBox>
        <BaseDetailBox flex="1 1 auto">
          <RiderTags riders={riders} lumpSumTopUp={lumpSumTopUp} />
        </BaseDetailBox>
        <BaseDetailBox>
          <span className="firstPeriod">{firstPeriod(productName)}</span>
          {totalPremium ? (
            <NumberInput
              value={totalPremiumPlusLumpSumTopUp(totalPremium, lumpSumTopUp)}
              displayType="text"
              suffix=" บาท"
              thousandSeparator
              decimalPrecision={2}
            />
          ) : (
            '-'
          )}
        </BaseDetailBox>
      </ProductDetail>
    </Flex>
  </div>
)).attrs({
  size: 'xl',
})`
  padding: 0 1rem;
  margin-top: 5px;
  ${NameBox}, > ${Flex} {
    padding-bottom: 10px;
  }
  ${NameBox} + ${ProductDetail} {
    border-top: 1px solid ${({ theme }) => theme.variables['$color-silver']};
    ${({ theme }) => theme.media.lg`
    border-top: none;
    padding: 0;
    `};
  }
  > ${Flex} {
    ${contentMaxWidth} border-bottom: 1px solid ${({ theme }) => theme.variables['$color-gray--darkest']};
  }
  .firstPeriod {
    color: ${({ theme }) => theme.variables['$color-dusty-gray']};
  }
`

export default connect(getQuickQuoteInsuredInformation)(InsuredInformationCard)
